import './App.css';
import Home from './components/Home';
import './components/Home.css';
import FactOfTheDay from "./components/FactOfTheDay";

function App() {
  return (
    <div className="App">
      <FactOfTheDay />
      <Home />
    </div>
  );
}

export default App;
