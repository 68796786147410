import React, { useState, useEffect }  from 'react';

const FactOfTheDay = () => {
    const dayOfWeek = new Date().toLocaleString("en-US", { weekday: "long" });

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetch("https://api.mlsvd.de/static/daily-data")
            .then((response) => {
                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
                return response.json();
            })
            .then((data) => {
                setData(data);
                setLoading(false);
            })
            .catch((error) => {
                setError(error.message);
                setLoading(false);
            });
    }, []);

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error}</p>;


    return (
        <div id="fact-of-the-day">
            <p><strong>Fact of the day:</strong> {data.fact}</p>
            <div id="gif-of-the-day" className="overlay">
                <div className="gif-container">
                    <img src={data.giphy} alt="Gif-of-the-day" className="gif"/>
                    <div className="main-text-container">
                        <h1>Happy {dayOfWeek}!</h1>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FactOfTheDay;